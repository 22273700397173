import React, { useState, useEffect } from 'react';
import Hub from './components/Hub';

// Definiere die Basis-URL abhängig von der Umgebung
const apiBaseUrl =
    process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_API_BASE_URL
        : process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

const socketUrl =
    process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_SOCKET_URL
        : process.env.REACT_APP_SOCKET_URL || 'ws://localhost:3000/ws';

function App() {
    const [playerId] = useState(1);
    const [inDungeon, setInDungeon] = useState(false);

    useEffect(() => {
        console.log("App loaded");
    }, []);

    const enterDungeon = () => {
        setInDungeon(true);
    };

    return (
        <div className="App">
            <h1>Dungeon Crawler</h1>
            {inDungeon ? (
                <p>Dungeon is not implemented yet</p>
            ) : (
                <Hub
                    playerId={playerId}
                    onEnterDungeon={enterDungeon}
                    apiBaseUrl={apiBaseUrl}  // Übergebe apiBaseUrl als Prop
                    socketUrl={socketUrl}    // Übergebe socketUrl als Prop
                />
            )}
        </div>
    );
}

export default App;
