import React, { useState } from 'react';

// Verwende Umgebungsvariablen für die Basis-URL

function Hub({ apiBaseUrl, socketUrl }) {
    const [playerName, setPlayerName] = useState('');
    const [player, setPlayer] = useState(null);
    const [error, setError] = useState(null);

    const createOrFetchPlayer = async () => {
        try {
            const response = await fetch(`${apiBaseUrl}/hub/player`, {  // Verwende apiBaseUrl hier
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name: playerName }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setPlayer(data);

            // Fetch inventory and equipment for the player
            await fetchPlayerDetails(data.id);
        } catch (error) {
            setError('Error creating or fetching player: ' + error.message);
            console.error('Error creating or fetching player:', error);
        }
    };

    const fetchPlayerDetails = async (playerId) => {
        try {
            const [inventoryResponse, equipmentResponse] = await Promise.all([
                fetch(`${apiBaseUrl}/hub/inventory/${playerId}`),
                fetch(`${apiBaseUrl}/hub/equipment/${playerId}`)
            ]);

            const inventory = await inventoryResponse.json();
            const equipment = await equipmentResponse.json();

            // Set player details, inventory, and equipment as needed
            console.log('Inventory:', inventory);
            console.log('Equipment:', equipment);
        } catch (error) {
            setError('Error fetching player details: ' + error.message);
            console.error('Error fetching player details:', error);
        }
    };

    return (
        <div>
            <h2>Create or Fetch Player</h2>
            <input
                type="text"
                value={playerName}
                onChange={(e) => setPlayerName(e.target.value)}
                placeholder="Enter player name"
            />
            <button onClick={createOrFetchPlayer}>Create or Fetch Player</button>
            {error && <p>{error}</p>}
            {player && <div>Player: {player.name}</div>}
        </div>
    );
}

export default Hub;
